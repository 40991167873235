<template>
  <el-container class="shopList">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>权限管理</el-breadcrumb-item>
        <el-breadcrumb-item>菜单权限</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt15">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="企业类型名称"
              v-model="page.company_type_name"
              clearable
              @clear="onSubmit"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search">
       <el-form :inline="true"  :model="page" class="demo-form-inline">
        <el-col :span="4" class="mr10">
             <el-form-item size="medium" >
         <el-input placeholder="企业类型名称" v-model="page.company_type_name" clearable class="input-with-select" @clear="onSubmit">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="4" class="tl">
           <el-form-item  size="medium">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
        </el-form-item>
        </el-col>
       
      </el-form>
    </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="company_type_name"
            min-width="180"
            :show-overflow-tooltip="true"
            label="企业类型名称"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            class-name="aa"
            fixed="right"
            min-width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="general_color disib"
                size="mini"
                @click="goUpdate(scope.row.company_type_id)"
                >菜单管理</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local, aa, session } from "../../../util/util";
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: {
        pager_offset: "0",
        pager_openset: "10",
        company_type_name: "",
        state: "1",
      },
      user: "",
      timeVisible: false,
      timer: "",
      loading: false,
      options4: "",
      options2: "",
      activeClass: 0,
      currentPage: 1,
      total: 0,
      count: "",
      tableData: [],
    };
  },
  created() {
    var that = this;
    that.user = local.get("user");
    that.currentPage = that.page.pager_offset / 10 + 1;
    that.getData(this.page);
  },
  methods: {
    //获取列表
    getData(params) {
      axios.get("/pc/company-type/list", params).then((v) => {
        this.tableData = v.data.company_type_list;
        this.total = v.data.pager_count;
      });
    },
    // 搜索
    onSubmit() {
      var that = this;
      that.currentPage = 1;
      that.page.pager_offset = "0";
      that.getData(that.page);
    },
    // 分页
    currentChange(page) {
      var that = this;
      that.currentPage = page;
      that.page.pager_offset = String((page - 1) * 10);
      that.getData(that.page);
    },
    //编辑
    goUpdate(id) {
      session.set("params", { id: id, name: "" });
      this.$router.push({ name: "MenuAdd" });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
